import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import "./style.css";
import WebMenu from "../../components/WebMenu";
import MobileMenu from "../../components/MobileMenu";
import Footer from "../../components/Footer";
import SbsLogo from "./../../assets/sbs.png";
import { valuesIcons } from "../../_mockup";
import IconCard from "../../components/IconCard";

const AboutUs = () => {
  return (
    <div className="about">
      <WebMenu />
      <MobileMenu type='' />
      <header className="about-header">
        <img src={SbsLogo} alt="Sbs logo" />
      </header>
      <div className="tabs-conatiner">
        <Tabs>
          <TabList>
            <Tab>About Us</Tab>
            <Tab>Our Team</Tab>
            <Tab>Our Values</Tab>
          </TabList>

          <TabPanel>
            <h4>About Us</h4>
            <p>
              SBS is an industry leading professional services solutions. Our
              aim is to deliver 100% quality product <br /> and success your
              business to achieve ultimate goal
            </p>
            <br />
            <h4>Our Success</h4>
            <p>
              Each of our projects starts with the simple question “How will you
              measure the success of this project?” Success is critical to our
              team; whether that success comes in the form of happy clients,
              loyal site users or solid metrics. To help us better understand
              the challenges of your project we try to establish not only the
              goals but what are your current returns, challenges and the
              opportunities for growth. Our approach has yielded many successful
              products and happy customers over the years.
            </p>
            <br />
            <h4>Our Difference</h4>
            <p>
              Each of our projects starts with the simple question “How will you
              measure the success of this project?” Success is critical to our
              team; whether that success comes in the form of happy clients,
              loyal site users or solid metrics. To help us better understand
              the challenges of your project we try to establish not only the
              goals but what are your current returns, challenges and the
              opportunities for growth. Our approach has yielded many successful
              products and happy customers over the years.
            </p>
          </TabPanel>
          <TabPanel className="our-team">
            <h4>Our Team</h4>
            <p>
              SBS team consists of highly qualified consultants, computer
              engineers, hardware technical consultants, graphic designers,
              developers, and professional certified project managers. This team
              has helped companies in the mobility world to carry on their work
              successfully and establish trust with our brand.
            </p>
            <br />
            <p>
              The people that make up SBS have years of experience working with
              some of the largest brands on the market today.
            </p>
            <br />
            <p>
              SBS team is one of the most homogeneous and professional. All of
              its members are working in harmony offering their utmost loyalty
              and dedications.
            </p>
            <br />
            <p>
              SBS team have gone beyond products and technology and decided to
              offer the localization knowledge and technology as the added value
              of any product and/or service that SBS well provide.
            </p>
            <br />
            <h4>Our Company team experiences</h4>
            <ul>
              <li>Change Management and Enterprise development</li>
              <li>Experienced IT people in the field of mobility</li>
              <li>Business Process Mapping, Automation and Optimization</li>
              <li>Software Development</li>
              <li>Project Management Experts</li>
              <li>Fintech and Digital payment</li>
              <li>Digital and technical transformation</li>
              <li>Consulting and Outsourcing</li>
              <li>System integration</li>
            </ul>
          </TabPanel>
          <TabPanel>
            <h4>Our Values</h4>
            <p>
              SBS values are the cornerstone of how we do business and the basis
              for our guiding principles and the culture of the company. They
              set the benchmark for minimum expectations and are the driving
              force behind our clear communication, superior performance, and
              professional integrity.
            </p>
            <div className="value-cards">
              {valuesIcons.map((val) => (
                <IconCard icon={val.icon} title={val.title} key={val.id} />
              ))}
            </div>
          </TabPanel>
        </Tabs>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
