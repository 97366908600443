import React, { FC, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import "./style.css";

interface Props {
  type?: "qr" | "bnpl" | "finpay";
}

const Footer: FC<Props> = ({ type }) => {
  const [showContent, setShowContent] = useState(false);
  const [indexValue, setIndexValue] = useState(0);

  const contentText = () => {
    switch (indexValue) {
      case 0:
        return (
          <>
            <h2>Egypt </h2>
            <h6>Telephone: </h6>
            <p>+20 11 432 571 17</p>
            <h6>E-mail: </h6>
            <p> info@bsas-eg.com</p>
            <h6>Address: </h6>
            <p>6 Degla st, Maadi, Cairo 11742</p>
          </>
        );
      case 1:
        return (
          <>
            <h2>Saudi Arabia </h2>
            <h6>Telephone: </h6>
            <p> +966 11 211 3300</p>
            <h6>E-mail: </h6>
            <p>info@sbs-sa.net</p>
            <h6>Address: </h6>
            <p>
              Business Valley – 4496 Anas Ibn Malik Rd, <br /> 3rd Floor, Riyadh
              13524
            </p>
          </>
        );
      case 2:
        return (
          <>
            <h2>USA </h2>
            <h6>Telephone: </h6>
            <p>+1 425 240-1532</p>
            <h6>E-mail: </h6>
            <p> info@bsas-us.com</p>
            <h6>Address: </h6>
            <p>30 N GOULD St Ste R Sheridab, WY 82801</p>
          </>
        );
    }
  };

  const handleShowContent = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    index: number
  ) => {
    e.preventDefault();
    setIndexValue(index);
    setShowContent(!showContent);
  };
  return (
    <div id="Footer" className={type ? `footer ${type}` : "footer"}>
      <div>
        <div className="title">
          <h2>Contact us</h2>
        </div>
        <div className="footer-btn">
          <Button onClick={(e) => handleShowContent(e, 0)}>Egypt</Button>
          <Button onClick={(e) => handleShowContent(e, 1)}>Saudi Arabia</Button>
          <Button onClick={(e) => handleShowContent(e, 2)}>USA</Button>
        </div>
        {showContent && <div className="btn-content">{contentText()}</div>}
        <div className="footer-content">
          <Row>
            <Col>
              <h2>Saudi Arabia </h2>
              <h6>Telephone: </h6>
              <p> +966 11 211 3300</p>
              <h6>E-mail: </h6>
              <p>info@sbs-sa.net</p>
              <h6>Address: </h6>
              <p>
                Business Valley – 4496 Anas Ibn Malik Rd, <br /> 3rd Floor,
                Riyadh 13524
              </p>
            </Col>
            <Col>
              <h2>Egypt </h2>
              <h6>Telephone: </h6>
              <p>+20 11 432 571 17</p>
              <h6>E-mail: </h6>
              <p> info@bsas-eg.com</p>
              <h6>Address: </h6>
              <p>6 Degla st, Maadi, Cairo 11742</p>
            </Col>
            <Col>
              <h2>USA </h2>
              <h6>Telephone: </h6>
              <p>+1 425 240-1532</p>
              <h6>E-mail: </h6>
              <p> info@bsas-us.com</p>
              <h6>Address: </h6>
              <p>30 N GOULD St Ste R Sheridab, WY 82801</p>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Footer;
