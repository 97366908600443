import homeIcon from './../assets/qrIcons/home.svg';
import pharm from './../assets/qrIcons/pharm.svg';
import taxi from './../assets/qrIcons/taxi.svg';
import guy from './../assets/qrIcons/Guy.svg';
import CharityIcon from './../assets/qrIcons/CharityIcon.svg';
import question from './../assets/qrIcons/question.svg';
import Fashion from './../assets/BnplIcons/FashionIcon.svg';
import clock from './../assets/BnplIcons/clock.svg';
import others from './../assets/BnplIcons/others.svg';
import screen from './../assets/BnplIcons/screen.svg';
import GamesIcon from './../assets/BnplIcons/GamesIcon.svg';
import FurnitureIcon from './../assets/BnplIcons/FurnitureIcon.svg';
import questionF from "./../assets/finpayIcons/question.svg";
import clarityF from "./../assets/finpayIcons/clarity.svg";
import deliveryF from "./../assets/finpayIcons/delivery.svg";
import homeIconF from "./../assets/finpayIcons/homeIcon.svg";
import pharmF from "./../assets/finpayIcons/pharm.svg";
import taxiF from "./../assets/finpayIcons/taxi.svg";

import Cube from './../assets/cube.svg'
import exe from './../assets/exe.svg'
import hand from './../assets/hand.svg'
import hands from './../assets/hands.svg'
import person from './../assets/person.svg'

export const qrIcons = [
    {
        id: 0,
        title: "Grocery",
        icon: homeIcon,
    },
    {
        id: 1,
        title: "Pharmacy",
        icon: pharm
    },
    {
        id: 2,
        title: "Taxi driver",
        icon: taxi
    },
    {
        id: 3,
        title: "Delivery",
        icon: guy
    },
    {
        id: 4,
        title: "Charity",
        icon: CharityIcon
    },
    {
        id: 5,
        title: "Any business",
        icon: question
    },
]
export const finpayIcons = [
    {
        id: 0,
        title: "Grocery",
        icon: homeIconF,
    },
    {
        id: 1,
        title: "Pharmacy",
        icon: pharmF
    },
    {
        id: 2,
        title: "Taxi driver",
        icon: taxiF
    },
    {
        id: 3,
        title: "Delivery",
        icon: deliveryF
    },
    {
        id: 4,
        title: "Charity",
        icon: clarityF
    },
    {
        id: 5,
        title: "Any business",
        icon: questionF
    },
]
export const bnplIcons = [
    {
        id: 0,
        title: "Fashion",
        icon: Fashion,
    },
    {
        id: 1,
        title: "Furniture",
        icon: FurnitureIcon
    },
    {
        id: 2,
        title: "Games",
        icon: GamesIcon
    },
    {
        id: 3,
        title: "Electronics",
        icon: screen
    },
    {
        id: 4,
        title: "Accessories",
        icon: clock
    },
    {
        id: 5,
        title: "& Others",
        icon: others
    },
]
export const valuesIcons = [
    {
        id: 0,
        title: "Acountability",
        icon: Cube,
    },
    {
        id: 1,
        title: "Mutual Respect",
        icon: hands
    },
    {
        id: 2,
        title: "Passion",
        icon: hand
    },
    {
        id: 3,
        title: "Integrity",
        icon: person
    },
    {
        id: 4,
        title: "Pursuit of Excellence",
        icon: exe
    },

]