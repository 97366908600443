import React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import Footer from '../../components/Footer';
import IconCard from '../../components/IconCard';
import ProjectNav from '../../components/ProjectNav';
import { bnplIcons } from '../../_mockup';
import stores from './../../assets/BnplIcons/stores.svg';
import qrcode from './../../assets/BnplIcons/qrcode.svg';
import shopCartHover from './../../assets/BnplIcons/shopping-cart-hover.svg';
import qrCartHover from './../../assets/BnplIcons/QRlogo-hover.svg';
import shopCart from './../../assets/BnplIcons/shopping-cart.svg';
import Chart from './../../assets/BnplIcons/Chart.svg';
import Chart2 from './../../assets/BnplIcons/Chart2.svg';
import Chart3 from './../../assets/BnplIcons/Chart3.svg';
import Chart4 from './../../assets/BnplIcons/Chart4.svg';
import Adidas from './../../assets/BnplIcons/Adidas.svg';
import Aldo from './../../assets/BnplIcons/Aldo.svg';
import hmLogo from './../../assets/BnplIcons/hm-logo.svg';
import Ikea from './../../assets/BnplIcons/Ikea.svg';
import Namshi from './../../assets/BnplIcons/Namshi.svg';
import Nike from './../../assets/BnplIcons/Nike.svg';
import rayBan from './../../assets/BnplIcons/ray-ban.svg';
import Boots from './../../assets/BnplIcons/Boots.svg';
import Shein from './../../assets/BnplIcons/Shein.svg';
import Zara from './../../assets/BnplIcons/Zara.svg';
import './style.css';
import FormCard from '../../components/FormCard';
import MobileMenu from '../../components/MobileMenu';

const Bnpl = () => {
	return (
		<div className="bnpl-project">
			<ProjectNav type="bnpl" />
			<MobileMenu type="bnpl" />
			<header className="bnpl-header">
				<h2>
					<span>Buy Now Pay Later app</span> <br />
					<span> Big goals, small payments</span>
				</h2>
				<p>
					From shopping offline in malls and stores to online shopping, purchase <br /> all you want now and
					pay later with no interest, applications, or fees.
				</p>
				<div className="download-app">
					<Button>Download app</Button>
				</div>
			</header>
			<div className="how-work">
				<div className="where-container">
					<Row>
						<Col>
							<h2>Where to use BNPL</h2>
							<p>
								BNPL enables you to shop from a wide variety
								<br /> of stores that fit all your needs, whether you
								<br /> buy online or in-store.
							</p>
						</Col>
						<Col>
							<div className="know-container">
								<div className="know-card">
									<img src={qrcode} alt="qrCode" />
									<img src={qrCartHover} alt="qrCartHover" />
									<h6>
										Scan and pay
										<br />
										at store
									</h6>
									<Button>Know more</Button>
								</div>
								<div className="know-card">
									<img src={shopCartHover} alt="shopCartHover" />
									<img src={shopCart} alt="shopCart" />
									<h6>
										directly on partners
										<br />
										apps
									</h6>
									<Button>Know more</Button>
								</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
			<div className="for-whom">
				<h2>What you can buy</h2>
				<h6>
					You can use BNPL to buy anything you want from our partner stores,
					<br /> offline and online. With a wide variety of stores that fit all your needs.
				</h6>
				<div className="whom-container">
					{bnplIcons.map((icon) => <IconCard key={icon.id} icon={icon.icon} title={icon.title} />)}
				</div>
			</div>

			<div className="how-work why-qr">
				<h2>Installment plans options</h2>
				<p>
					BNPL offers multiple options for your purchase installment
					<br />
					Choose whatever suits you freely
				</p>
				<div className="work-item-container">
					<div className="work-item">
						<img src={Chart} alt="Chart" />
						<h5>One Payment</h5>
						<h6>
							Pay it all within 30 days
							<br />
							0% interest
						</h6>
					</div>
					<div className="work-item">
						<img src={Chart2} alt="Chart2" />
						<h5>3 Payments</h5>
						<h6>
							3 Monthly payments
							<br />
							0% interest
						</h6>
					</div>
					<div className="work-item">
						<img src={Chart3} alt="Chart3" />
						<h5>6 Payments</h5>
						<h6>
							6 Monthly payments
							<br />
							0% interest
						</h6>
					</div>
					<div className="work-item">
						<img src={Chart4} alt="Chart4" />
						<h5>9 Payments</h5>
						<h6>
							9 Monthly payments
							<br />
							0% interest
						</h6>
					</div>
				</div>
			</div>

			<div className="Eligibility">
				<h2>Eligibility criteria with BNPL?</h2>
				<ul>
					<li>An applicant must be of minimum age of 18 years.</li>
					<li>An applicant should be a citizen or a resident.</li>
					<li>An applicant should have a valid credit card with minimum SAR 10,000.</li>
					<li>An applicant’s minimum monthly income should not be lower than SAR. 10,000.</li>
				</ul>
			</div>

			{/* <div className="how-work why-qr">
				<h2>Our Merchant Partners</h2>
				<p />
				<div className="work-item-container part">
					<Row>
						<Col className="work-item">
							<img src={Shein} alt="Shein" />
						</Col>
						<Col className="work-item">
							<img src={Nike} alt="Nike" />
						</Col>
						<Col className="work-item">
							<img src={Aldo} alt="Aldo" />
						</Col>
						<Col className="work-item">
							<img src={Zara} alt="Zara" />
						</Col>
						<Col className="work-item">
							<img src={Adidas} alt="Adidas" />
						</Col>
					</Row>
				</div>
				<div className="work-item-container part">
					<Row>
						<Col className="work-item">
							<img src={Namshi} alt="Namshi" />
						</Col>
						<Col className="work-item">
							<img src={Ikea} alt="Ikea" />
						</Col>
						<Col className="work-item">
							<img src={Boots} alt="Boots" />
						</Col>
						<Col className="work-item">
							<img src={hmLogo} alt="hmLogo" />
						</Col>
						<Col className="work-item">
							<img src={rayBan} alt="rayBan" />
						</Col>
					</Row>
				</div>
				<h3>and more..</h3>
			</div> */}

			<div className="payment-container">
				<div>
					<Row>
						<Col>
							<FormCard
								title={'Register as a partner'}
								content={'Add your store to BNPL Partners List'}
								haveImage={false}
								type="bnpl"
								btnTitle="Register"
							/>
						</Col>
						<Col className="scan-container">
							<h2>Register as a customer</h2>
							<p>Let’s Buy Now Pay Later</p>
							<img src={stores} alt="qr" />
						</Col>
					</Row>
				</div>
			</div>
			<Footer type="bnpl" />
		</div>
	);
};

export default Bnpl;