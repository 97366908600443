import React from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import Footer from "../../components/Footer";
import IconCard from "../../components/IconCard";
import ProjectNav from "../../components/ProjectNav";
import { qrIcons } from "../../_mockup";
import visa from "./../../assets/qrIcons/visa.svg";
import master from "./../../assets/qrIcons/master.svg";
import mada from "./../../assets/qrIcons/mada.svg";
import History from "./../../assets/qrIcons/History.svg";
import Join from "./../../assets/qrIcons/Join.svg";
import Server from "./../../assets/qrIcons/Server.svg";
import QR from "./../../assets/qrIcons/QR.svg";
import TimeSaving from "./../../assets/qrIcons/TimeSaving.svg";
import easy from "../../assets/qrIcons/easy.png";
import qrMobile from '../../assets/qrIcons/qr_mobile-image.png'
import "./style.css";
import FormCard from "../../components/FormCard";
import Featuers from "../../components/Featuers";
import MobileMenu from "../../components/MobileMenu";
import card1 from '../../assets/card-1.png'
import card2 from '../../assets/card-2.png'
import card3 from '../../assets/card-3.png'

const QrProject = () => {
  return (
    <div className="qr-project">
      <ProjectNav type="qr" />
      <MobileMenu type='qr' />
      <header className="qr-header">
        <h2>
          <span>QR code payment solution</span>
          <br />
          Payments at a glance
        </h2>
        <p>
          Our QR code payment solution enables merchants to accept payments
          <br />
          without the need for an EDC or a POS machine. Just generate a QR{" "}
          <br /> code, customers scan with their mobile phones & go.
        </p>
        <div className='contact-btn'>
          <Button href="#Footer">Contact Us</Button>
        </div>
      </header>
      <div className="how-work">
        <h2>How it works?</h2>
        <div className="work-item-container">
          <div className="work-item">
            <p>1</p>
            <h5>Generate</h5>
            <h6>
              Merchant generates a payment
              <br /> QR code
            </h6>
          </div>
          <div className="work-item">
            <p>2</p>
            <h5>Scan</h5>
            <h6>
              Client scans the payment QR <br /> code with their phone
            </h6>
          </div>
          <div className="work-item">
            <p>3</p>
            <h5>Pay</h5>
            <h6>
              Payment is done. No need for <br /> any manual work
            </h6>
          </div>
        </div>
      </div>
      <div className="for-whom">
        <h2>For whom</h2>
        <h6>
          QR code payment solution fits all your customers and their businesses.
          <br /> They will be able to accept digital payments with their smart
          wallet app or
          <br /> digital payment apps like Visa scan to pay app.
          <br />
          With dynamic and static code options our solution suits...
        </h6>
        <div className="whom-container">
          {qrIcons.map((icon) => (
            <IconCard key={icon.id} icon={icon.icon} title={icon.title} />
          ))}
        </div>
      </div>
      <div className="payment-container">
        <div>
          <Row>
            <Col>
              <h2>
                QR code payment solution <br />
                is certified by
              </h2>
              <p>
                Customers can pay whatever their payment <br /> scheme is.
                Payment by Visa, MasterCard, <br /> Mada, or any e-wallet is
                accepted.
              </p>
            </Col>
            <Col className="cards-container">
              {/* <img src={mada} alt="mada" /> */}
              <img src={visa} alt="visa" />
              <img src={master} alt="mada" />
            </Col>
            <div className="bank-cards-mobile">
                {/* <img src={card1} alt="mada" /> */}
                <img src={card2} alt="visa" />
                <img src={card3} alt="master" />
              </div>
          </Row>
        </div>
      </div>
      <Featuers image={easy} mobileFinbay={qrMobile} />
      <div className="how-work why-qr">
        <h2>Why QR code payment solution?</h2>
        <p>
          With this solution, merchants are able to get up & running and start{" "}
          <br /> accepting payments once the app is downloaded and the their
          account
          <br /> is activated
        </p>
        <div className="work-item-container">
          <div className="work-item">
            <img src={Join} alt="Join" />
            <h5>Easy to join</h5>
            <h6>
              Get on-board & create your 1st QR <br /> code easily
            </h6>
          </div>
          <div className="work-item">
            <img src={TimeSaving} alt="TimeSaving" />
            <h5>Time saving</h5>
            <h6>
              Recive payment whenever you need, <br /> wherever you want it
            </h6>
          </div>
          <div className="work-item">
            <img src={History} alt="History" />
            <h5>Payments history</h5>
            <h6>
              Check your transactions <br />
              and payments history
            </h6>
          </div>
          <div className="work-item">
            <img src={Server} alt="Server" />
            <h5>Always available</h5>
            <h6>
              100% Up-time, no bugs, <br />
              no server downtime
            </h6>
          </div>
        </div>
      </div>

      <div className="payment-container">
        <div>
          <Row>
            <Col>
              <FormCard
                title={"Get in touch"}
                content={
                  "Interested in our QR code payment solution? Get in touch and we will assist you"
                }
                haveImage={false}
                type="qr"
              />
            </Col>
            <Col className="scan-container">
              <h2>Or scan this QR code</h2>
              <p>Just scan the code to contact us easily</p>
              <img src={QR} alt="qr" />
            </Col>
          </Row>
        </div>
      </div>
      <Footer type="qr" />
    </div>
  );
};

export default QrProject;
