import React from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import Logo from "./../../assets/Logo.svg";
import QR from "./../../assets/qrMenu.svg";
import BNPL from "./../../assets/BNPL.svg";
import finbay from "./../../assets/finbay.svg";
import sliceTop from "./../../assets/sliceTop.svg";

const CustomNav = () => {
  return (
    <div className='web-menu'>
      <Navbar bg="light" expand="lg">
      <Container fluid className="nav-container">
        <Link className="logo-container" to="/">
          <img src={Logo} alt="logo" />
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Link className="navbar-links" to="/services">
              Our services
            </Link>
            <NavDropdown title="Our products" id="navbarScrollingDropdown">
              <img src={sliceTop} alt="Slice" className="slice-top" />
              <NavDropdown.Item>
                <Link to="/qr" className="to-projects">
                  <div className="menu-item">
                    <img src={QR} alt="Qr" />
                    <p>QR code payment solution</p>
                  </div>
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Link to="/bnpl" className="to-projects">
                  <img src={BNPL} alt="BNPL" />
                </Link>
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item>
                <Link to="/finpay" className="to-projects">
                  <img src={finbay} alt="finbay" />
                </Link>
              </NavDropdown.Item>
            </NavDropdown>
            <Link className="navbar-links" to="/about">
              About us
            </Link>

            <Nav.Link className="navbar-links" href="#Footer">
              Contact us
            </Nav.Link>
          </Nav>
          <Button className="lang-btn">العربية</Button>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </div>
  );
};

export default CustomNav;
