import React, { useState, FC } from 'react';
import { Button, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './style.css';
import Logo from './../../assets/Logo.svg';
import QR from './../../assets/qrIcons/qrMenu.png';
import BNPL from './../../assets/BNPL.svg';
import finbay from './../../assets/finbay.svg';
import sliceTop from './../../assets/sliceTop.svg';
import closeIcon from '../../assets/close-icon.png';
import mobileMenuIcon from '../../assets/mobile-menu-icon.png';
import finpayHome from './../../assets/finbay.svg';
import finpaymobileMenuIcon from '../../assets/finpay-mobile-icon.png';
import qrMenuIcon from '../../assets/qr-menu-icon.png';
import bnplMenuIcon from '../../assets/bnpl-mobile-icon.png';

interface Props {
	type: 'qr' | 'bnpl' | 'finpay' | '';
}

const MobileMenu: FC<Props> = ({ type }) => {
	const [ menuToggle, setMenuToggle ] = useState(false);

	return (
		<div className="mobile-side-menu">
			<div className="menu-image">
				{type === '' && (
					<Link className="logo-container" to="/">
						<img src={Logo} alt="logo" />
					</Link>
				)}
				{type === 'finpay' && (
					<Link className="logo-container" to="/">
						<img src={finpayHome} alt="finpayHome" />
					</Link>
				)}
				{type === 'qr' && (
					<Link className="logo-container" to="/">
						<img src={QR} alt="logo" />
						<span className="qr-style">QR code payment solution</span>
					</Link>
				)}
				{type === 'bnpl' && (
					<Link className="logo-container" to="/">
						<img src={BNPL} alt="logo" />
					</Link>
				)}
			</div>
			<div className="menu-items">
				<div className="burger-menu">
					{type === '' && (
						<button className="" onClick={() => setMenuToggle(!menuToggle)}>
							<img src={mobileMenuIcon} alt="mobile-menu" />
						</button>
					)}
					{type === 'finpay' && (
						<button className="" onClick={() => setMenuToggle(!menuToggle)}>
							<img src={finpaymobileMenuIcon} alt="mobile-menu" />
						</button>
					)}
					{type === 'qr' && (
						<button className="" onClick={() => setMenuToggle(!menuToggle)}>
							<img src={qrMenuIcon} alt="mobile-menu" />
						</button>
					)}
					{type === 'bnpl' && (
						<button className="" onClick={() => setMenuToggle(!menuToggle)}>
							<img src={bnplMenuIcon} alt="mobile-menu" />
						</button>
					)}
				</div>
				{menuToggle && (
					<div className={`menu ${menuToggle ? 'open' : 'close'}`}>
						<button className="close-btn" onClick={() => setMenuToggle(false)}>
							<img width="25px" height="25px" src={closeIcon} alt="collapsed-menu" />
						</button>
						<ul>
							<li>
								<Link className="navbar-links" to="/services">
									Our services
								</Link>
							</li>
							<li>
								<NavDropdown title="Our products" id="navbarScrollingDropdown">
									<img src={sliceTop} alt="Slice" className="slice-top" />
									<NavDropdown.Item>
										<Link to="/qr" className="to-projects">
											<div className="menu-item">
												<img src={QR} alt="Qr" />
												<p>QR code payment solution</p>
											</div>
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item>
										<Link to="/bnpl" className="to-projects">
											<img src={BNPL} alt="BNPL" />
										</Link>
									</NavDropdown.Item>
									<NavDropdown.Divider />
									<NavDropdown.Item>
										<Link to="/finpay" className="to-projects">
											<img src={finbay} alt="finbay" />
										</Link>
									</NavDropdown.Item>
								</NavDropdown>
							</li>
							<li>
								<Link className="navbar-links" to="/about">
									About us
								</Link>
							</li>
							<li>
								<Nav.Link className="navbar-links" href="#Footer" onClick={() => setMenuToggle(!menuToggle)}>
									Contact us
								</Nav.Link>
							</li>
						</ul>
						<Button className="lang-btn _roboto-bold">العربية</Button>
					</div>
				)}
			</div>
		</div>
	);
};

export default MobileMenu;