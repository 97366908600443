import "bootstrap/dist/css/bootstrap.min.css";
import React from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/Home";
import Services from "./screens/Services";
import Products from "./screens/Products";
import AboutUs from "./screens/AboutUs";
import ContactUs from "./screens/ContactUs";
import QrProject from "./screens/QrProject";
import Bnpl from "./screens/BNPL";
import Finpay from "./screens/Finbay";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/services" element={<Services />} />
        <Route path="/products" element={<Products />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/qr" element={<QrProject />} />
        <Route path="/bnpl" element={<Bnpl />} />
        <Route path="/finpay" element={<Finpay />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
