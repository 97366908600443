import React, { FC } from "react";
import "./style.css";

interface Props {
  title: string;
  icon: string;
}

const IconCard: FC<Props> = ({ title, icon }) => {
  return (
    <div className="icon-card-container">
      <img src={icon} alt="icon" />
      <p>{title}</p>
    </div>
  );
};

export default IconCard;
