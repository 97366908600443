import React, { FC } from "react";
import { Button, Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./style.css";
import Logo from "./../../assets/Logo.svg";
import brownHome from "./../../assets/brownHome.svg";
import bnplHome from "./../../assets/BnplIcons/home.svg";
import finpayHome from "./../../assets/finpayIcons/home.svg";
import QR from "./../../assets/qrMenu.svg";
import BNPL from "./../../assets/BNPL.svg";
import finpay from "./../../assets/finbay.svg";

interface Props {
  type: "qr" | "bnpl" | "finpay";
}

const ProjectNav: FC<Props> = ({ type }) => {
  return (
    <Navbar bg="light" expand="lg" id="proj-nav">
      <Container fluid className="nav-container">
        <Link className="logo-container" to="/">
          {type === "qr" ? (
            <>
              <img src={QR} alt="logo" />
              <p>QR code payment solution</p>
            </>
          ) : type === "bnpl" ? (
            <img src={BNPL} alt="logo" />
          ) : (
            <img src={finpay} alt="logo" />
          )}
        </Link>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll" className="justify-content-end">
          <Button className="lang-btn">العربية</Button>
          <Link to="/">
            {type === "qr" ? (
              <img src={brownHome} alt="Home" />
            ) : type === "bnpl" ? (
              <img src={bnplHome} alt="bnplHome" />
            ) : (
              <img src={finpayHome} alt="finpayHome" />
            )}
          </Link>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default ProjectNav;
